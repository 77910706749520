[
    {
        "name": "Cuisine",
        "slug": "cuisine",
        "expanded": false,
        "brands": [
            {
                "name": "Saveurs",
                "slug": "saveurs",
                "sendinBlueListId": "4"
            },
            {
                "name": "Saveurs Nutrition",
                "slug": "saveurs-nutrition"
            },
            {
                "name": "Family Cuisine",
                "slug": "family-cuisine",
                "sendinBlueListId": "33"
            },
            {
                "name": "Saveurs Green",
                "slug": "saveurs-green",
                "sendinBlueListId": "5"
            },
            {
                "name": "Saveurs Simplissime",
                "slug": "saveurs-simplissime"
            },
            {
                "name": "Saveurs Thermomix",
                "slug": "saveurs-thermomix"
            },
            {
                "name": "Five Starr",
                "slug": "fivestarr"
            }
        ]
    },
    {
        "name": "Lifestyle",
        "slug": "lifestyle",
        "expanded": false,
        "brands": [
            {
                "name": "Au Coeur des Régions",
                "slug": "aucoeurdesregions"
            },
            {
                "name": "Esprit d'ici",
                "slug": "esprit-dici",
                "sendinBlueListId":  "2"
            },
            {
                "name": "Horoscope",
                "slug": "horoscope",
                "sendinBlueListId":  "12"
            },
            {
                "name": "Silence ça pousse !",
                "slug": "silencecapousse",
                "sendinBlueListId":  "14"
            }
        ]
    },
    {
        "name": "Crimes et enquêtes",
        "slug": "crimes-enquetes",
        "expanded": false,
        "brands": [
            {
                "name": "Le Nouveau Détective",
                "slug": "lenouveaudetective",
                "sendinBlueListId":  "10"
            },
            {
                "name": "L'Envers des Affaires",
                "slug": "lenversdesaffaires",
                "sendinBlueListId":  "53"
            },
            {
                "name": "L'Heure du Crime",
                "slug": "heure-du-crime"
            }
        ]
    },
    {
        "name": "Spiritualité",
        "slug": "spiritualite",
        "expanded": false,
        "brands": [
            {
                "name": "New Witch",
                "slug": "newwitch"
            },
            {
                "name": "Mortem",
                "slug": "mortem"
            }
        ]
    },
    {
        "name": "Santé",
        "slug": "sante",
        "expanded": false,
        "brands": [
            {
                "name": "Comment ça va ?",
                "slug": "commentcava"
            }
        ]
    }
]
